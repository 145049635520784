<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" order="2" order-sm="2" order-md="1" order-lg="1"
          md="12" lg="12" class="pt-5 pl-2">
          <h4 class="title">Proyek Ujian</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          <v-dialog v-model="dialogImpor" max-width="700px">
            <v-card>
              <v-card-title>
                <span>Impor Data</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <input type="file" @change="onFileChange" />
                  </v-col>
                  <v-col cols="12" sm="6"  md="6">
                    <a href="/data_soal.xlsx">Download template Excel</a>
                  </v-col>
                </v-row>
              </v-container>
              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="hdImpor"
                    :items="dtImporx"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :footer-props="{
                      'items-per-page-options': [30, 40, 50]
                    }"
                    :items-per-page="30"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cyan" @click="dialogImpor = false">Cancel</v-btn>
                <v-btn color="cyan" @click="saveImpor">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-dialog v-model="konfirmHapus" max-width="400px">
          <v-card>
            <v-card-title>
            <span>Konfirmasi</span>
            </v-card-title>
            <v-card-text>
            <v-container>
              <span>Anda yakin menghapus data ini?</span>
            </v-container>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
              <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="konfirmHapus2" max-width="400px">
          <v-card>
            <v-card-title>
            <span>Konfirmasi2</span>
            </v-card-title>
            <v-card-text>
            <v-container>
              <span>Anda yakin menghapus data ini?</span>
            </v-container>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="konfirmHapus2 = false">Batal</v-btn>
              <v-btn color="blue darken-1" @click="delData2">Ya</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <v-row class="px- mx-auto mt-20" v-if="st_menu=='hasil'">
        <v-col cols="12" md="12">
          <v-icon class="mr-2" @click="st_menu='list'">mdi-keyboard-backspace</v-icon>
          Hasil Ujian
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="text_cari_sis" label="cari..." v-on:keyup="KeydownSis">></v-text-field>
        </v-col>
        <v-col  cols="6" sm="3" md="3">
          <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="lokal_kelas_ujian" label="Lokal"
            v-model="kelas_ujian_id" required v-on:change="gtKelasUjian()"> </v-select>
        </v-col>
        <v-col cols="3" md="3">
          <v-btn @click="fnExcel()" small>Excel</v-btn>
        </v-col>

        <v-col cols="12" sm="12">
          <v-card class="mx-auto" tile>
            <v-data-table
              :headers="hdUjian"
              :items="ftUjianSiswa"
              :disable-pagination="false"
              :hide-default-footer="false"
              :loading="loadingHasil"
              loading-text="Loading... Please wait"
              :footer-props="{
                'items-per-page-options': [30, 40, 50]
              }"
              :items-per-page="30"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <div v-if="item.sis_ujian_id">
                  <v-icon class="mr-2" @click="fnDetilHasil(item.sis_ujian_id)">mdi-eye</v-icon>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
<!-- :class="{ merah: item.jawab=='a' }"-->
      <v-row class="px- mx-auto mt-20" v-if="st_menu=='detHasil'">
        <v-col cols="12" md="12">
          <v-icon class="mr-2" @click="st_menu='hasil'">mdi-keyboard-backspace</v-icon>
          Detil Hasil Ujian Siswa
        </v-col>
        <v-col cols="12" sm="12" class="px- mx-auto ml-20">
          <v-card class="mx-auto" tile v-for="item in lsDetilHasil" v-bind:key="item.sis_ujian_id2">
            {{ item.nomor }}. <span v-html="item.soal"></span>
            <br> a. <span v-html="item.a"></span> <v-icon class="mr-0" v-if="item.jawab=='a'">mdi-check</v-icon>
            <br> b. <span v-html="item.b"></span> <v-icon class="mr-0" v-if="item.jawab=='b'">mdi-check</v-icon>
            <br> c. <span v-html="item.c"></span> <v-icon class="mr-0" v-if="item.jawab=='c'">mdi-check</v-icon>
            <br> d. <span v-html="item.d"></span> <v-icon class="mr-0" v-if="item.jawab=='d'">mdi-check</v-icon>
            <br> e. <span v-html="item.e"></span> <v-icon class="mr-0" v-if="item.jawab=='e'">mdi-check</v-icon>
            <br>
            <v-card-actions>
              Kunci. {{ item.kunci }} , Jawaban : {{ item.jawab }}  Hasil :
              <div v-if = "item.benar==1" class="hijau">
                Benar
              </div>
              <div v-else class="merah">
                Salah
              </div>

            </v-card-actions>
            <br> 
          </v-card>
        </v-col>
      </v-row>

      <v-row class="px- mx-auto mt-20" v-if="st_menu=='list'">
        <v-col cols="12" md="6">
          <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn color="cyan" class="mb-0" @click="fnBaru()" small>+ Data</v-btn>
        </v-col>

        <v-col cols="12" sm="12">
          <v-card class="mx-auto" tile>
            <v-data-table
              :headers="headers"
              :items="ftData"
              :disable-pagination="false"
              :hide-default-footer="false"
              :loading="loading"
              loading-text="Loading... Please wait"
              :footer-props="{
                'items-per-page-options': [30, 40, 50]
              }"
              :items-per-page="30"
            >
              <template v-slot:[`item.cek_lokal`]="{ item }">
                <v-icon class="mr-2" @click="fnHasil(item.ujian_id)">mdi-checkbox-multiple-marked-outline</v-icon>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="fnEdit(item.ujian_id)">mdi-pencil </v-icon> 
                <v-icon class="mr-2" @click="fnDetil(item.ujian_id,1)">mdi-grid</v-icon>
                <!--
                <v-icon class="mr-2" @click="fnDetil(item.ujian_id,2)">mdi-view-module</v-icon>
                -->
                <v-icon @click="fnKonfirmasi(item.ujian_id)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row valign="top" class="px- mx-auto mt-0" v-if="st_menu=='list2'">
          <v-col cols="12" md="6">
            Data Soal
            <v-text-field v-model="text_cari_soal" label="cari soal..." v-on:keyup="fnCariSoal()">></v-text-field>
            Total Skor : {{ totalSkor }}
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="cyan" class="mb-0" @click="fnSoal()" small>+ Soal</v-btn>
            <v-btn color="cyan" class="mb-0" @click="st_menu='imporWord'" small>Copy Text</v-btn>
            <v-btn color="cyan" class="mb-0" @click="fnImpor()" small>Import Excel</v-btn>
            <v-btn color="cyan" class="mb-0" @click="fnPreview()" small> {{ st_view }} </v-btn>
            <v-btn color="red" class="mb-0" @click="st_menu='list'" small>Tutup</v-btn>
          </v-col>
          <v-col cols="12" sm="12" v-if="st_view == 'view'">
            <v-card class="mx-auto" tile v-for="item in ftDataSoal" v-bind:key="item.soal_id">
              {{ item.nomor }}. <span v-html="item.soal" dangerouslySetInnerHTML> </span>
              <br> a. <span v-html="item.a"></span>
              <br> b. <span v-html="item.b"></span>
              <br> c. <span v-html="item.c"></span>
              <br> d. <span v-html="item.d"></span>
              <br> e. <span v-html="item.e"></span>
              <br>
              <v-card-actions>
                  Kunci. {{ item.kunci }}
                </v-card-actions>
                <br> 
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" v-else>
            <v-card class="mx-auto" tile>
              <v-data-table
                :headers="hdSoal"
                :items="ftDataSoal"
                :disable-pagination="false"
                :hide-default-footer="false"
                :footer-props="{
                  'items-per-page-options': [30, 40, 50]
                }"
                :items-per-page="30"
              >  
              <template v-slot:[`item.nomor`]="{ item }">
                <div class="v-align">{{item.nomor}}</div>
              </template>
                <template v-slot:[`item.soal`]="{ item }">
                  <span v-html="item.soal"></span>
                  <br>a. <span v-html="item.a"></span>
                  <br>b. <span v-html="item.b"></span>
                  <br>c. <span v-html="item.c"></span>
                  <br>d. <span v-html="item.d"></span>
                  <br>e. <span v-html="item.e"></span>
                  <br>
                  <div :style="{color: ' #f67e7e'}" >
                  Kunci Jawaban : <span v-html="item.kunci" style="color='red'"></span> skor : <b>{{ item.n_benar}} </b>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEditSoal(item.soal_id)">mdi-pencil </v-icon>
                  <v-icon small @click="fnKonfirmasi2(item.soal_id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="st_menu=='add'" class="pt-5 pl-2">
          <v-spacer></v-spacer>
              <br><br><br>
              <v-col cols="12" v-if="loadingSave===true">  
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-text-field label="Nama Ujian" v-model="nm_ujian"></v-text-field>
            </v-col>
            <v-col  cols="12" sm="4" md="4">
              <v-select item-text="nama" item-value="id" :items="lsJUjian"
                label="Jenis Ujian" v-model="ju_id" required></v-select>
            </v-col>
              <v-col  cols="12" sm="4" md="4">
              <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas"
              v-model="kelas_id" required v-on:change="gtKelas()"></v-select>
            </v-col>
            <v-col  cols="12" sm="4" md="4">
              <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" outlined :items="ftKelasLokal" label="Lokal"
                v-model="kelasLokal_id" required multiple chips></v-select>
            </v-col>
              <v-col  cols="12" sm="4" md="4">
              <v-select item-text="label" item-value="id"  :items="ddMapel" label="Mata Pelajaran" v-model="mapel_id" required></v-select>
            </v-col>

            <v-col  cols="12" sm="4" md="4">
              <v-select item-text="label" item-value="id" :items="ddSemester" label="Semester" v-model="semester_id" required></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              Tanggal
              <input type="date" v-model="tgl1" format="DD/MM/YYYY"  placeholder="Tanggal">
            </v-col>
            <v-col cols="12" sm="4" md="4">
              Jam
              <input type="time" v-model="jam" format="hh:mm"  placeholder="Jam">
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field label="Lama (menit)" v-model="menit"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field label="Kode Akses" v-model="kode"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field label="Jumlah Soal" v-model="jml"></v-text-field>
            </v-col>
              <v-col cols="12" sm="6" md="6">
                Random Soal
              <v-radio-group v-model="is_rand">
                <v-radio
                  label="Ya" value="1"
                >
                </v-radio>
                <v-radio
                  label="Tidak" value="0"
                ></v-radio>
                </v-radio-group>
            </v-col>    
            <v-col cols="12" sm="4" md="4">
              <v-switch
                v-model="is_pub"
                flat
                :label="`Publikasi ${is_pub.toString()}`"
              ></v-switch>
            </v-col>
            <v-spacer></v-spacer> 
            <v-col cols="12" sm="12" md="12" v-if="loadingSave===false"> 
              <v-btn color="cyan" @click="st_menu  = 'list'">Cancel</v-btn>
              <v-btn color="cyan" @click="saveData">Save</v-btn>
            </v-col> 
            <v-col cols="12" sm="6" md="6" v-else>
                Proses simpan data
            </v-col>
        </v-row>

            <v-row v-if="st_menu=='addSoal'" class="pt-5 pl-5">
              <v-spacer></v-spacer>
                <br><br><br>
                <v-col cols="12">
                  Soal :
                  <wysiwyg v-model="soal" />
                </v-col>
                <v-col cols="12">
                  Pilihan a :
                  <wysiwyg v-model="pil_a" />
                </v-col>
                          <v-col cols="12">
                  Pilihan b :
                  <wysiwyg v-model="pil_b" />
                </v-col>
                          <v-col cols="12">
                Pilihan c :
                  <wysiwyg v-model="pil_c" />
                </v-col>
                          <v-col cols="12">
                  Pilihan d :
                  <wysiwyg v-model="pil_d" />
                </v-col>
                          <v-col cols="12">
                  Pilihan e :
                  <wysiwyg v-model="pil_e" />
                </v-col>

                <v-col  cols="12" sm="2" md="2">
                  <v-select item-text="id" item-value="id" :items="lsKunci"
                    label="Jawaban" v-model="kunci_id" required></v-select>
                </v-col>
                <v-col  cols="12" sm="2" md="2">
                  <v-text-field label="Nilai Benar" v-model="n_benar" @keypress="isNumber($event)" type="number"   
                    step="any"
                    min="1"></v-text-field>
                </v-col>
                <v-col  cols="12" sm="2" md="2">
                  <v-text-field label="Nilai Salah" v-model="n_salah" @keypress="isNumber($event)" step="any"
                    min="0"></v-text-field>
                </v-col> 
                <v-spacer></v-spacer>
                <v-btn color="cyan" @click="st_menu  = 'list2'">Cancel</v-btn>
                <v-btn color="cyan" @click="saveSoal">Save</v-btn>
            </v-row>
              <v-row v-if="st_menu=='imporWord'" class="pt-5 pl-5">
                <br>
                <v-col cols="12" md="6">
                  Import soal dari text / notepad
                </v-col>
                <v-col cols="12">
                  <v-btn color="cyan" @click="st_menu  = 'list2'">tutup</v-btn>
                  <v-btn color="cyan" @click="fnImporWord()">Impor</v-btn>
                  <v-btn color="cyan" @click="fnRemHTML()">hapus</v-btn>
                </v-col>
                <v-col cols="12">
                  <ckeditor v-model="editorData" :config="editorConfig"></ckeditor>
                </v-col>
            </v-row>

          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
   
<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";
import 'vue2-datepicker/index.css';
//import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import XLSX from 'xlsx';

export default {
  data: () => ({
    editorData: '<p>Copy dan paste soal di sini halo</p> ',
    mathJaxLib : '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML',
    //editor:ClassicEditor,
    editorConfig: {
      addExternal:('ckeditor_wiris', '../node_modules/@wiris/mathtype-ckeditor4/', 'plugin.js'),
      extraPlugins:'ckeditor_wiris,image2,uploadimage,justify,font,mathjax',
      //allowedContent: true,
      removePlugins: 'image',
      toolbar: [[ 'Source','[a','ckeditor_wiris_formulaEditor' ,']','Wiris'],
        [ 'Styles', 'Format', 'Font', 'FontSize' ],
        [ 'Bold', 'Italic','EasyImageUpload','uploadimage','Image' ],
        [ 'Undo', 'Redo' ],
        [ 'About' ],['ckeditor_wiris_formulaEditor', 'ckeditor_wiris_formulaEditorChemistry']],
      height: '500px',
      width:'800px',
      FullPage : false,
      ProtectedTags : 'html|head|body',
      cloudServices_uploadUrl: api + 'uploadSoalGbr',
      cloudServices_tokenUrl: '', removeButtons: 'PasteFromWord',
      filebrowserUploadUrl: api + 'uploadSoalGbr',
      uploadUrl: api + 'uploadSoalGbr',
      removeDialogTabs: 'image:advanced;link:advanced',
      mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML',
      //fullpage:true,
      disallowedContent : 'a[target],a,span',
      pasteFromWord_inlineImages : true,
      pasteFilter: 'semantic-content h1 h2 p ul ol li; img[!src, alt]; a[!href]',
      forcePasteAsPlainText : true,
      pasteFromWordRemoveFontStyles : true,
      pasteFromWordRemoveStyles : true,
      // disallowedContent :[
       // 'style','span; *[on*]','script; *[on*]','a,*[on*]'
    //  ], 
    removeFormatTags:'b,big,cite,code,del,dfn,em,font,i,ins,kbd,q,s,samp,small,span,strike,strong',
      extraAllowedContent: [
    'math','img[!src,alt]',
    'maction',
    'maligngroup',
    'malignmark',
    'menclose',
    'merror',
    'mfenced',
    'mfrac',
    'mglyph',
    'mi',
    'mlabeledtr',
    'mlongdiv',
    'mmultiscripts',
    'mn', 'img[data-mathml,data-custom-editor,role](Wirisformula)'], 
        //extraAllowedContent: this.mathElements.join(' ') + '(*)[*]{*};img[data-mathml,data-custom-editor,role](Wirisformula)',
         //removePlugins: 'image,filetools,uploadimage,uploadwidget,uploadfile,filebrowser',

    },dtImporWord:[],
    konfirmHapus:false, is_pub:true, st_pub:null,
    konfirmHapus2:false, ftKelasLokal:[],totalSkor:0,
    snackbar: false, lsKelasLokal :[],kelasLokal_id : [],
    tgl1:new Date(), kelas_ujian_id:null, text_cari_soal:'',
    st_menu:'list', text_cari_sis:'', st_view:'table',
    jam:0, text_cari:'', loadingHasil:false,
    is_rand:"1", n_benar : 1,n_salah:0,
    lsStatus:false, loadingdetHasil : false,
    text: 'OK',
    timeout: 2000,
    title:'',menit:30,jml:20,
    loading: true, loadingSave: false,
    lsData : [], dtImporx:[],
    ftData : [],
    lsDataSoal : [],ftDataSoal : [],
    ujian_id:0,kunci_id:'a',
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    nm_ujian: '',
    kode: '',
    soal_id:0, lokal_kelas_ujian:[],kelas_lok_id:null,
    soal:'',pil_a:'',pil_b:'',pil_c:'',pil_d:'',pil_e:'',
    yt_id: '', sis_ujian_id:null,
    id: 0,
    lokal:'',
    ddMapel : [], lsDetilHasil:[],
    dtImpor:[],
    files: null,
    mapel_id:'',stDetil:null,
    kelas_id:1,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],lsUjianSiswa:[],ftUjianSiswa:[],
    lsTA:[],
    lsJMedia:[],id_hapus:0,id_hapus2:0,
    ju_id:0,is_yt:false,
    image: {
      uploadURL: api + 'uploadSoalGbr',
      dropzoneOptions: {}
    },
     hideModules: { "bold": true },
 
  // limit content height if you wish. If not set, editor size will grow with content.
  maxHeight: "10px",
  ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
  lsKunci:[{"id":"a"},{"id":"b"},{"id":"c"},{"id":"d"},{"id":"e"}],
  headers: [
    { text: "Judul", align: "start", sortable: false, value: "nm_ujian" },
    { text: "Jenis", value: "nmJujian", sortable: false },
    { text: "Mata Pelajaran", value: "nm_mapel", sortable: false },
    { text: "Hasil", value: "cek_lokal", sortable: false },
    { text: "Aksi", value: "actions", sortable: false },
  ],
  hdUjian: [
    //{ text: "NIS", align: "start", sortable: false, value: "nm_ujian" },
    { text: "Nama Siswa", value: "nama", sortable: false },
    { text: "Benar", value: "benar", sortable: false },
    { text: "Salah", value: "salah", sortable: false },
    { text: "Nilai", value: "nilai", sortable: false },
    { text: "Koreksi", value: "actions", sortable: false },
  ],
  hdDetil: [
    { text: "Nama Siswa", value: "nama", sortable: false },
    { text: "Benar", value: "benar", sortable: false },
    { text: "Salah", value: "salah", sortable: false },
    { text: "Nilai", value: "nilai", sortable: false },
    { text: "Koreksi", value: "actions", sortable: false },
  ],
  hdSoal: [
    { text: "No", align: "start", width:20, sortable: false, value: "nomor", },
    { text: "Soal", value: "soal", width:"75%", sortable: false },
    { text: "Analisis", value: "analisis", sortable: false },
    { text: "Aksi", value: "actions", sortable: false },
  ],
  hdImpor: [
    { text: "Soal", align: "start", sortable: false, value: "soal" },
    { text: "Aksi", value: "actions", sortable: false },
  ],
  }),
  components:{
    Navbar,//DatePicker 
  },
  
  methods: {
    fnRemHTML(){
      let datax = this.editorData
      let regex = /(<\/?(?:|img)[^>]*>)(<([^>]+)>)/ig
      datax = datax.replace(regex,"",1)
      datax = datax.replaceAll('<span lang="IN" style="background:white">',"")
      console.log('cek data ', datax)
      this.editorData = datax
    },
    fnImporWord(){
      this.dtImporx=[]
      const datax = this.editorData.split("soal@")
      console.log('cek data ', datax)
      if(datax.length > 1){
        //let i = 1
        this.dtImporx=[]
        datax.forEach((value ) => {
          if(value.length >=5){
            const cek_soal=value.split("pilih@a.")
            const soal = cek_soal[0]
            if(cek_soal[1].length>2){
              const pilihan = cek_soal[1].split("kunci@")
              let opsi = pilihan [0]

              let opsi_a = opsi.split("pilih@b.")
              let pil_a = opsi_a[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',"")
              let opsi_b = opsi_a[1].split("pilih@c.")
              let opsi_c = opsi_b[1].split("pilih@d.")
              let opsi_d = opsi_c[1].split("pilih@e.")
              let opsi_e = opsi_d[1]

              console.log(' cek a :', pil_a, ' cek b :', opsi_b[0], ' cek c :', opsi_c[0], 
              ' cek d :', opsi_d[0], ' cek e :', opsi_e)
              const t_kunci = pilihan[1].split("n_benar@")
              let kunci = t_kunci[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',"")
              let n_benar = t_kunci[1].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',"")
              n_benar = n_benar.replaceAll('</p>','')
              //console.log('cek jawab', this.$sanitize(t_kunci[0]), ' kunci baru ', kunci, ' n_benar ',n_benar )
              //console.log('cek soal ', cek_soal, soal, ' pilihan ', t_kunci , i)
              const dataku = {
                soal: soal,
                a: pil_a,
                b: opsi_b[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',""),
                c: opsi_c[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',""),
                d: opsi_d[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',""),
                e: opsi_e.replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',""),
                kunci: kunci,
                n_benar: n_benar,
                n_salah: 0,
              }
              this.dtImporx.push(dataku)
              
            }
          }
          //i++
        })
        this.saveImpor()
        console.log('cek data ', this.dtImporWord)
      }else{
        this.text = 'Data kosong'
        this.snackbar =true
      }
    },
    fnExcel() {
      const kelasku=this.lsKelasLokal.filter(m => m.kelas_lok_id.includes(this.kelas_ujian_id))
      const dataExcel = []
      const judul = [
        'Laporan Data Hasil Ujian' + ' Kelas : ' + kelasku[0]['nm_lokal_kelas']
      ]
      dataExcel.push(judul)
      const dataku = [
        'No',
        'Nama Siswa',
        'Benar',
        'Salah',
        'Nilai'
      ]
      dataExcel.push(dataku)
      let i=1
      this.ftUjianSiswa.forEach((value ) => {
        const dataku = [
          i,value['nama'],
          value['benar'],
          value['salah'],
          value['nilai'],
          //value['nm_semester']
        ]
        dataExcel.push(dataku)
        i++
      })
      var wscols = [
        {wch:10},
        {wch:30},
        {wch:15},
        {wch:20},
        {wch:20},
        {wch:20}
      ];
      const data = XLSX.utils.json_to_sheet(dataExcel)
      const wb = XLSX.utils.book_new()
      data["!cols"] = wscols
      data["A1"]=''
      data["B1"]=''
      data["C1"]=''
      data["D1"]=''
      data["E1"]=""
      //console.log('cek data ku ' , dataExcel )
      XLSX.utils.book_append_sheet(wb, data, 'data hasil ujian')
      XLSX.writeFile(wb,'hasil_ujian.xlsx')
    }, 
    fnCariSoal: function () {
      if(this.text_cari_soal.length>0){
        this.ftDataSoal = this.lsDataSoal.filter(m => m.soal.toUpperCase().includes(this.text_cari_soal.toUpperCase()))
      }else{
        this.ftDataSoal = this.lsDataSoal
      }
    },
    KeydownSis: function () {
      if(this.text_cari_sis.length>0){
        this.ftUjianSiswa = this.lsUjianSiswa.filter(m => m.nama.toUpperCase().includes(this.text_cari_sis.toUpperCase()))
      }else{
        this.ftUjianSiswa = this.lsUjianSiswa
      }
    },
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nm_ujian.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    isNumber(evt) {  
      const charCode = evt.which ? evt.which : evt.keyCode;  
      if (  
        charCode > 31 &&  
        (charCode < 48 || charCode > 57) &&  
        charCode !== 46  
      ) {  
        evt.preventDefault();  
      }  
    },  
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      this.dtImporx=[]
      readXlsxFile(xlsxfile).then((rows) => {
        rows.forEach((value,index ) => {
        if(index > 1) {
          const dataku = {
            soal: value[1],
            a: value[2],
            b: value[3],
            c: value[4],
            d: value[5],
            e: value[6],
            kunci: value[7],
            n_benar: value[8],
            n_salah: value[9],
          }
          this.dtImporx.push(dataku)
        }
      });
        this.dtImpor=rows
      })
    },
    saveImpor() {
      const cekImpor = Number(this.dtImporx.length);
      if (cekImpor < 1) {
        this.text = 'Data kosong'
        this.snackbar =true
      } else {
        const dataku = {
          ujian_id: this.ujian_id,
          data:this.dtImporx
        }
        console.log('cek data ', this.dtImporx, dataku)

        axios
          .post(api + 'imporSoal', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialogImpor = false
              this.fnDTSoal()             
            } else {
              this.text = 'Data kosong'
              this.snackbar =true
            }
          })

      }
    },
    fnPreview(){
      if(this.st_view=='table'){
        this.st_view = 'view'
      }else{
        this.st_view = 'table'
      }
      //console.log('cek view ', this.st_view)
    },
    gtKelas(){
      this.kelasLokal_id=[]
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.includes(this.kelas_id))
      //console.log(' kelas id ', this.kelas_id)
      //console.log( ' cek lokal x ' ,this.lsKelasLokal)
    },
    async fnImpor() {
      this.dialogImpor=true
    },
    gtKelasUjian(){
      this.loadingHasil = true
      axios
      .get(api + 'hasilUjian&kelas_lok_id=' + this.kelas_ujian_id + '&ujian_id=' + this.id)
      .then(response => {
        //console.log('hasilUjian&kelas_lok_id=' + this.kelas_ujian_id + '&ujian_id=' + this.id)
        //console.log('cek data siswa ujian ', response.data)
        if (response.data.cek === 1) {
          this.lsUjianSiswa = response.data.data
          this.ftUjianSiswa = response.data.data
        }else{
          this.lsUjianSiswa = []
          this.ftUjianSiswa = []
        }
        this.loadingHasil = false
      })
    },
    pilihGbr () {
      if(this.files){
        //console.log('cek ', this.files)  
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
        .post(api + 'uploadPdf', formData)
        .then(response => {
          //console.log('cek ', response.data)
          this.fileGbr = response.data.data
          this.fileGbr2 = response.data.fgbr
          if (response.data.cek === 1) {
            console.log(' sukses ')
          } else {
            console.log(' tidak sukses ')
          }
        })
      }
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
      })
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    fnKonfirmasi2(id){
      this.id_hapus2=id
      this.konfirmHapus2=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        ujian_id: this.id_hapus
      }
      axios
        .post(api + 'delGUjian', dataku)
        .then(response => {
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()         
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    delData2 () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        soal_id: this.id_hapus2
      }
      axios
        .post(api + 'delGSoal', dataku)
        .then(response => {
          //console.log('cek hapus soal', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus2=false
            this.fnDTSoal()
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    clsForm () {
      this.kelas_id=null
      this.nm_ujian=''
      this.kode=''
      this.tgl1 = ''
      this.judul = ''
      this.fileGbr = ''
    },
    saveData () {
      this.loadingSave = true
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.jm_id)
        if(this.is_pub==true){
          this.st_pub = 1
        }else{
          this.st_pub = 0
        }
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_id: this.kelas_id,
          semester_id: this.semester_id,
          mapel_id: this.mapel_id,
          guru_id: this.$store.state.user.guru_id,
          nm_ujian: this.nm_ujian,
          kode:this.kode,
          tgl1:this.tgl1,
          id: this.id,
          jam: this.jam,
          is_rand:this.is_rand,
          is_pub:this.st_pub,
          kelasLokal_id:this.kelasLokal_id,
          ju_id:this.ju_id,menit:this.menit,jml:this.jml,
          sk_id:this.$store.state.user.sk_id
        }

        //console.log('cek dataku ', dataku)
        axios
          .post(api + 'saveGUjian', dataku)
          .then(response => {
            //console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.loadingSave = false
              this.fnData()
              this.st_menu='list'
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      //}
    },
    saveSoal() {
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.jm_id)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          ujian_id: this.ujian_id,
          soal: this.soal,
          pil_a: this.pil_a,
          pil_b: this.pil_b,
          pil_c: this.pil_c,
          pil_d:this.pil_d,
          pil_e:this.pil_e,
          kunci_id:this.kunci_id,
          soal_id:this.soal_id,
          sk_id:this.$store.state.user.sk_id,
          n_benar:this.n_benar,
          n_salah:this.n_salah
        }
        //console.log('cek ' , dataku)
        axios
          .post(api + 'saveGSoal', dataku)
          .then(response => {
            //console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.fnDTSoal()
              this.st_menu = 'list2'
            } else {
              console.log(' tidak sukses ')
            }
          })
          this.fnTotalskor()
      //}
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnJUjian () {
      axios
        .get(api + 'lsJUjian&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJUjian = response.data.data
            //console.log('cek lsJMedia ', response.data.data)
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    fnTotalskor() {
      let total = 0;
      for (let item in this.lsDataSoal) {
       // console.log(' item ', item)
        total = total + Number(this.lsDataSoal[item].n_benar)
      }
      this.totalSkor= total
    },
    async fnData () {
      axios
      .get(api + 'lsGUjian&user_id=' + this.$store.state.user.user_id)
      .then(response => {
        //console.log('cek data ujian ', response.data)
        if (response.data.cek === 1) {
          this.lsData = response.data.data
          this.ftData = response.data.data
        }
      })
    },
    async fnDTSoal () {
      //this.sis_ujian_id=id
      //console.log('cek ', this.stDetil)
      this.lsDataSoal = []
      this.ftDataSoal = []
      if(this.stDetil==1){
        axios
        .get(api + 'lsGSoal&ujian_id=' + this.ujian_id)
        .then(response => {
          console.log('cek data soal ', api + 'lsGSoal&ujian_id=' + this.ujian_id)
          if (response.data.cek === 1) {
            this.lsDataSoal = response.data.data
            this.ftDataSoal = response.data.data
          }else{
            //console.log('cek kosong')
            this.lsDataSoal = []
            this.ftDataSoal = []
          }
          this.fnTotalskor()
        })
      }else{
        axios
        .get(api + 'lsGSoal2&ujian_id=' + this.ujian_id)
        .then(response => {
          //console.log('cek ujian id ', api + 'lsGSoal2&ujian_id=' + this.ujian_id)
          if (response.data.cek === 1) {
            this.lsDataSoal = response.data.data
            this.ftDataSoal = response.data.data
          }else{
            this.lsDataSoal = []
            this.lsDataSoal = []
          }
          this.fnTotalskor()
        })
      }
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.id=0
      
      this.clsForm()
      this.gtKelas()
      this.st_menu='add'
    },
    async fnSoal() {
      this.clsSoal()
      this.st_menu='addSoal'
    }, 
    async fnDetilHasil(id) {
     // console.log('cek detil hasil ', id)
     //
     this.lsDetilHasil = []
        axios
        .get(api + 'lsDetilHasil&sis_ujian_id=' + id)
        .then(response => {
          if (response.data.cek === 1) {
            //console.log(' response.data.data ', response.data.data)
            this.lsDetilHasil = response.data.data
          }
        })

     
      this.st_menu='detHasil'
    },
    async fnDetil(id,b) {
      this.id=0
      this.ujian_id=id
      this.stDetil = b
      this.fnDTSoal()
      this.st_menu='list2'
    },
    async fnEditSoal (id) {
      const cekIndex = this.lsDataSoal.findIndex(cek => cek.soal_id === id)
      const dtEdit = this.lsDataSoal[cekIndex]
      this.soal_id = dtEdit.soal_id
      this.soal = dtEdit.soal
      this.pil_a = dtEdit.a
      this.pil_b = dtEdit.b
      this.pil_c = dtEdit.c
      this.pil_d = dtEdit.d
      this.pil_e = dtEdit.e
      this.kunci_id = dtEdit.kunci
      this.n_benar = dtEdit.n_benar
      this.n_salah = dtEdit.n_salah
      this.st_menu = 'addSoal'
      // this.itemsPaginated[cekIndex].qty = Number(this.itemsPaginated[cekIndex].qty) + 1
    },
    async fnEdit (id) {
      console.log('cek id ', id)
      const cekIndex = this.lsData.findIndex(cek => cek.ujian_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.kelas_id = dtEdit.kelas_id
      this.nm_ujian = dtEdit.nm_ujian
      this.kode = dtEdit.kode
      this.jml = dtEdit.jml
      this.menit = dtEdit.menit
      this.tgl1 = dtEdit.tgl_ujian
      this.jam = dtEdit.jam_ujian
      this.is_rand = dtEdit.is_rand
      this.mapel_id = dtEdit.mapel_id
      this.ju_id = dtEdit.ju_id
      this.semester_id = dtEdit.semester_id
      this.fileGbr = dtEdit.file
      if(dtEdit.is_pub =='1'){
        this.is_pub = true
      }else{
        this.is_pub = false
      }

      this.gtKelas()
      this.kelasLokal_id = dtEdit.lokalx
      this.st_menu = 'add'
    },
    async fnHasil(id) {
      this.lsUjianSiswa = []
      this.ftUjianSiswa = []
      const cekIndex = this.lsData.findIndex(cek => cek.ujian_id === id)
      const dtEdit = this.lsData[cekIndex]
      //console.log('cek ujian ', dtEdit)
      this.id = id
      //this.lokal_kelas_ujian = dtEdit.cek_lokal
      this.kelas_id = dtEdit.kelas_id
      this.nm_ujian = dtEdit.nm_ujian
      this.kode = dtEdit.kode
      this.jml = dtEdit.jml
      this.menit = dtEdit.menit
      this.tgl1 = dtEdit.tgl_ujian
      this.jam = dtEdit.jam_ujian
      this.is_rand = dtEdit.is_rand
      this.mapel_id = dtEdit.mapel_id
      this.ju_id = dtEdit.ju_id
      this.semester_id = dtEdit.semester_id
      this.fileGbr = dtEdit.file
      this.kelasLokal_id = dtEdit.lokalx
      this.kelas_ujian_id = null

      let datax = this.lsKelasLokal.filter(m => m.kelas_id.includes(dtEdit.kelas_id))
      
      this.lokal_kelas_ujian=[]
      datax.forEach((value ) => {
        let lokalx = dtEdit.lokalx
        lokalx.forEach((val ) => {
          let datab = value.kelas_lok_id
          if(datab === val){
            this.lokal_kelas_ujian.push(value)
          }
        })
      })
      this.st_menu = 'hasil'
    },
    clsSoal () {
      this.soal_id = 0
      this.soal = ''
      this.pil_a =''
      this.pil_b = ''
      this.pil_c = ''
      this.pil_d = ''
      this.pil_e = ''
      this.n_benar = '1'
      this.n_salah = '0'
      this.kunci_id= 'a'
    }

  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnJUjian()
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
    this.fnKelasLokal()
    
  }
}
</script>

<style lang="scss" scoped>
.merah {
  color: red;
}
.hijau {
  color: green;
}
</style>
